<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p><v-icon class="mdi">mdi-account-circle</v-icon>마이페이지</p>
                <div class="buttonArea">
                    <button class="x" @click="closePopup(false)"><i class="micon">close</i></button>
                </div>
            </div>
            <div class="popupContentArea">
                <ul class="cont">
                    <li>
                        <div class="titArea">
                            <p class="tit">기본 정보</p>
                            <button class="btn solid small" @click="openChangePwPopup()" v-ripple>비밀번호 변경</button>
                        </div>
                        <ul class="contArea">
                            <li>
                                <p>구분</p>
                                <span>{{ userRole&&userRole === 'A'? '병원관리자':'일반사용자' }}</span>
                            </li>
                            <li>
                                <p>ID</p>
                                <span>{{ userInfo.loginId }}</span>
                            </li>
                            <li>
                                <p>이름</p>
                                <span>{{ userInfo.userNm }}</span>
                            </li>
                            <li>
                                <p>상태</p>
                                <span :class="userInfo.useYn ==='Y'?'active':'nonActive'">{{ userInfo.useYn === 'Y'? '활성화':'비활성화' }}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="titArea">
                            <p class="tit">추가 정보</p>
                            <button v-if="!isChangingNote" class="btn default small" @click="isChangingNote = true">수정</button>
                            <button v-else class="btn solid small" @click="changeUserNote()">저장</button>
                        </div>
                        <div>
                            <ul class="contArea">
                                <li>
                                    <p>비고</p>
                                    <v-textarea flat solo density="compact" hide-details="auto" v-model="userInfo.note" :disabled="!isChangingNote" />
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 비밀번호 변경 팝업 -->
        <v-dialog v-model="chgPwPopupVisible">
            <ChangePwPopup
                :onClick="() => { popupVisible = false }"
                :userNo="userNo"
                :initialValues="initialValues"
                :initPwYn="initPwYn"
                @closePopup="closeChangePwPopup"
            />
        </v-dialog>
    </div>
</template>
<script>
import API_CHANGE_NOTE from '@/API/user/changeNote';
import { rules } from '../mixin/rules'

export default {
    components: {
        ChangePwPopup: () => import('../popup/changePwPopup.vue'),
    },
    mixins: [rules],
    props: {
        requestNo: {
            default: null
        },
        patientNo: {
            default: null
        }
    },
    data() {
        return {
            isGetingItems: false,
            chgPwPopupVisible: false,
            userNo: '',
            initialValues: {
                newPw: '',
                chkPw: ''
            },
            initPwYn: 'N',
            isChangingNote: false,
            originalNote: ''
        };
    },
    watch: {},
    created() {},
    mounted() {
        if(this.userInfo) this.userNo = this.userInfo.no
    },
    destroyed() {},
    methods: {
        closePopup(progress) {
            this.$emit('closeMyPagePopup', progress);
        },
        openChangePwPopup() {
            this.chgPwPopupVisible = true;
        },
        closeChangePwPopup() {
            this.chgPwPopupVisible = false;
        },
        async changeUserNote(){
            if(this.originalNote === this.userInfo.note) {
                this.showPopup('기존 비고의 내용과 동일합니다.')
                this.isChangingNote = false;
                return
            }
            let res = await API_CHANGE_NOTE.request(this.userNo, this.userInfo.note);
            if (res.isSuccess) {
                this.showPopup('비고 내용이 변경되었습니다.')
                this.isChangingNote = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
        }
    },
    computed:{},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup {
    .box{
        width: 580px;
        max-height: 95vh;
        .x{position: static;}
        .popTitle{
            display:flex; justify-content: space-between; align-items: center;
            p{
                display:flex; justify-content: flex-start; align-items: center;
                i{margin-right: 8px;}
            }
        }
        .cont{
            .titArea{
                display: flex; justify-content: space-between; margin-bottom: 10px; align-items: center;
                .tit{margin-bottom: 0;}
                .btn.small{min-width: 120px;}
            }
            >li{
                margin-bottom: 25px;
                &:last-child{margin-bottom: 0;}
            }
            ul.contArea{
                padding-left: 0;
                li{
                    display: flex; align-items: center; padding: 0 0 4px 8px; font-size: 14px;
                    p{width: 30%;}
                    span{width: 70%; padding: 0 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; height: 30px; line-height: 30px; color: #111 !important; text-align: center;}
                }
            }
        }
    }
}
.borderWrap{border: 1px solid #dbdbdb;}
.tblScroll{
    max-height: 250px;
    border-radius: 4px;
    .tbl{
        tr:hover{cursor: pointer;}
        border-top:0;
        border-bottom: 0;
        thead th{font-size: 14px; padding: 12px;}
        td{font-size: 14px; padding: 12px; height: auto;}
    }
}
::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}

::v-deep .v-input__slot .v-text-field__slot textarea{padding: 10px !important;}
.infoBox{margin-bottom:20px;}
.infoBox ul{padding: 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; margin-bottom: 20px;}
.infoBox ul li{color:#111; padding: 0 0 4px 8px; font-size: 14px;}
.infoBox p{text-align: center; font-size: 15px; line-height: 2rem; font-weight: bold;}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{padding: 0;}
::v-deep .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) textarea{margin-top: 0 !important; font-size: 14px; line-height: 1.8rem;}

span.active{font-weight:bold;}
</style>

